/**
 * Replaces spaces with hyphens and converts to lowercase
 * @param slug
 */
export function createSlugFromName(slug: string): string {
   return slug.replaceAll(' ', '-').toLowerCase();
}

/**
 * Translate the order status
 * @param status
 */
export function translateOrderStatus(status: string): string {
   switch (status) {
      case 'paid':
         return 'Betaald';
      case 'awaiting_payment':
         return 'Wachten op betaling';
      case 'cancelled':
         return 'Geannuleerd';
      case 'refunded':
         return 'Terugbetaald';
      default:
         return 'Onbekend';
   }
}

/**
 * Format price to 2 decimals
 *
 * @param price
 * @param free
 * @returns {string}
 */
export function formatPrice(price: number, free = 0.00) {
   if (isNaN(price)) {
      return "-";
   }

   if (price === 0) {
      return free.toString();
   }

   return (price / 100).toFixed(2).toString().replace(".", ",");
}
