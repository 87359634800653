<template>
    <TreeView
        ref="treeview"
        :modelDefaults="modelDefaults"
        :loadNodesAsync="getTaxonomies"
        selectionMode="multiple"
        @treeNodeCheckboxChange="(taxonomy, event) => checkboxChange(taxonomy, event)"
        @treeNodeClick="(taxonomy, event) => nodeClick(taxonomy, event)"
    />
</template>
<script setup lang="ts">
    import { TreeView } from '@grapoza/vue-tree';
    import { onBeforeMount, ref, watch, inject } from 'vue';
    import jsonpath from 'jsonpath';

    const axios = inject('axios');

    const props = defineProps({
        productTaxonomies: null,
        treeDefaults: {}
    });

    const emit = defineEmits(['onNodeClick', 'onCheckboxChange']);

    const treeview = ref(null);
    const taxonomies = ref([]);

    const modelDefaults = ref({
        state: {
            expanded: true,
            selected: false
        },
        customizations: {
            classes: {
                treeViewNodeSelf: 'my-2',
                treeViewNodeSelfCheckbox: 'text-primary focus:ring-primary',
                treeViewNodeSelfText: 'ml-0'
            }
        }
    });

    onBeforeMount(() => {
        modelDefaults.value = Object.assign({}, modelDefaults.value, props.treeDefaults);
    });

    const nodeClick = (taxonomy, event) => {
        emit('onNodeClick', taxonomy, event);
    };

    const checkboxChange = (taxonomy, event) => {
        emit('onCheckboxChange', taxonomy, event);
    };

    const getCheckedCheckboxes = () => {
        return treeview.value.getCheckedCheckboxes();
    };

    // Expose the methods so we can use it from parent component
    defineExpose({
        getCheckedCheckboxes
    });

    // Check if the productTaxonomies change at any point and refill the taxonomies again
    watch(
        () => props.productTaxonomies,
        () =>  fillTaxonomies()
    );

    const getTaxonomies = async () => {
        try {
            const response = await axios.get('/admin/taxonomies');

            if (response.status === 200) {
                taxonomies.value = response.data;
            }

            fillTaxonomies();

            return taxonomies.value ?? [];
        } catch (e) {
            console.error('Something went wrong!');
        }
    };

    const fillTaxonomies = () => {
        if (props.productTaxonomies?.length && taxonomies.value.length) {
            props.productTaxonomies.forEach((c) => {
                // Loop over taxonomies and find the id of the product taxonomy
                jsonpath.apply(taxonomies.value, `$..[?(@.id=="${c}")]`, (value) => {
                    // Set the checkbox value to true
                    value.treeNodeSpec.state.input.value = true;
                    return value;
                });
            });
        }
    };
</script>
