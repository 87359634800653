<template>
    <div class="inline-flex flex-col gap-y-4">
        <label
            :for="name"
            class="px-4 py-2 text-sm font-semibold leading-6 bg-primary hover:bg-primary/80 text-white border border-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 transition duration-300 cursor-pointer"
        >
            <slot>{{ label }}</slot>
        </label>
        <input
            :key="fileInputKey"
            :id="name"
            :name="name"
            type="file"
            class="sr-only"
            @change="onFileUpload"
            :required="required"
            :disabled="disabled"
        />
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    export interface Props {
        type?: string;
        label: string;
        name: string;
        required?: boolean;
        disabled?: boolean;
        readonly?: boolean;
        modelValue: any;
    }

    // Changing this key resets the file input, so we can upload another file
    const fileInputKey = ref(0);

    const props = withDefaults(defineProps<Props>(), {
        type: 'text'
    });

    const onFileUpload = (e: Event) => {
        if (!e.target) {
            return;
        }

        const file = e.target.files[0];
        emits('update:modelValue', file);
        fileInputKey.value++;
    };

    const emits = defineEmits(['update:modelValue', 'onFileUpload']);
</script>
