<template>
    <div>
        <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
        <div class="mt-1 flex flex-col gap-y-2">
            <div class="flex items-center h-5" v-for="option in options" :key="option.name">
                <input
                    :value="option.name"
                    @change="$emit('update:modelValue', checked)"
                    :id="option.name"
                    :name="option.name"
                    type="checkbox"
                    v-model="checked"
                    class="focus:ring-primary h-4 w-4 text-primary border-gray-300"
                />
                <div class="ml-2 text-sm">
                    <label :for="option.name" class="font-medium text-gray-700">{{
                        option.name
                    }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    export interface Option {
        name: string;
    }

    const props = defineProps<{
        label: string;
        modelValue: Array<string>;
        name: string;
        options: Array<Option>;
    }>();

    const checked = ref([]);

    const emits = defineEmits(['update:modelValue']);
</script>
