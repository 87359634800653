<template>
    <div>
        <h3 class="text-lg leading-8 mt-3 font-medium text-gray-900">
            {{ editMode ? 'Product details' : 'Nieuw product' }}
        </h3>
    </div>

    <PPAlert :error="error" />

    <div class="grid grid-cols-4 space-x-4">
        <nav class="sticky top-0 flex flex-col col-span-1 p-4 h-fit" aria-label="Sidebar">
            <ul role="list" class="space-y-1">
                <template v-for="item in tabs" :key="item.name">
                    <li v-if="showTabItem(item)">
                        <button
                            @click="currentTab = item.name"
                            :class="[
                                item.name === currentTab
                                    ? 'bg-gray-50 text-primary'
                                    : 'text-gray-700 hover:text-primary hover:bg-gray-50',
                                'group w-full flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold'
                            ]"
                        >
                            {{ item.name }}
                        </button>
                    </li>
                </template>
            </ul>
        </nav>

        <div class="col-span-3 my-4">
            <template v-if="currentTab === 'Details'">
                <form @submit.prevent="submit">
                    <div class="grid grid-cols-1 sm:grid-cols-2">
                        <PPToggle
                            name="published"
                            v-model="productForm.published"
                            label="Gepubliceerd"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPInput
                            v-model="productForm.code"
                            name="code"
                            label="Code"
                            :errors="v$.code.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPInput
                            v-model="productForm.pickCode"
                            name="pick_code"
                            label="Orderpick code"
                            :errors="v$.pickCode.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPInput
                            v-model="productForm.name"
                            @input="onNameInput(productForm.name)"
                            name="name"
                            label="Naam"
                            :errors="v$.name.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPInput
                            v-model="productForm.slug"
                            name="slug"
                            label="Slug"
                            :errors="v$.slug.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPTextArea
                            v-model="productForm.description"
                            name="description"
                            label="Omschrijving"
                            :errors="v$.description.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPMultiSelect
                            v-model="productForm.channels"
                            :options="channels"
                            name="channels"
                            label="Kanalen"
                            :errors="v$.channels.$errors.length"
                        />
                    </div>

                    <hr class="my-5" />

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPTextArea
                            v-model="productForm.metaKeywords"
                            name="meta_keywords"
                            label="Meta sleutelwoorden"
                            :errors="v$.metaKeywords.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPTextArea
                            v-model="productForm.metaDescription"
                            name="meta_description"
                            label="Meta omschrijving"
                            :errors="v$.metaDescription.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPTextArea
                            v-model="productForm.descriptionShort"
                            name="description"
                            label="Korte omschrijving"
                            :errors="v$.descriptionShort.$errors.length"
                        />
                    </div>

                    <hr class="my-5" />

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPSelect
                            :options="taxCategories"
                            v-model="productForm.taxCategory"
                            name="tax_category"
                            label="Belastingtarief"
                            :errors="v$.taxCategory.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPInput
                            type="number"
                            v-model="productForm.price"
                            name="price"
                            label="Prijs"
                            :errors="v$.price.$errors.length"
                        />
                    </div>

                    <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
                        <PPInput
                            type="number"
                            v-model="productForm.priceOriginal"
                            name="original_price"
                            label="Oorspronkelijke prijs"
                            :errors="v$.priceOriginal.$errors.length"
                        />
                    </div>
                </form>
            </template>

            <div v-show="currentTab === 'Taxonomie'">
                <div class="grid grid-cols-1 sm:grid-cols-2">
                    <PPSelect
                        name="main_taxonomy"
                        :options="taxonomies"
                        :allowNone="true"
                        v-model="productForm.mainTaxonomy"
                        label="Hoofdcategorie"
                    />
                </div>

                <label for="tree" class="mt-3 block text-sm font-medium text-gray-700">
                    Categorieën
                </label>
                <PPCategoryTree
                    ref="treeview"
                    @onCheckboxChange="(target, event) => onCheckboxChange(target, event)"
                    :productTaxonomies="productForm.taxonomies"
                    :treeDefaults="treeDefaults"
                />
            </div>

            <template v-if="currentTab === 'Attributen'">
                <h2 class="text-sm font-medium mb-3">Hoofdattributen</h2>

                <div class="border-y border-gray-100 mt-3">
                    <dl class="divide-y divide-gray-100">
                        <div class="grid grid-cols-5 items-center py-3">
                            <dt class="text-sm font-medium leading-6 text-gray-900 col-span-2">
                                Categorie
                            </dt>
                            <dd class="col-span-3">
                                <PPSelect
                                    name="category"
                                    :options="categoryOptions"
                                    v-model="productForm.category"
                                    label=""
                                    :allowNone="true"
                                />
                            </dd>
                        </div>

                        <div class="grid grid-cols-5 items-center py-3">
                            <dt class="text-sm font-medium leading-6 text-gray-900 col-span-2">
                                Gewicht (gr)
                            </dt>
                            <dd class="col-span-3">
                                <PPInput
                                    name="weight"
                                    step="0.1"
                                    type="number"
                                    v-model="productForm.weight"
                                    label=""
                                />
                            </dd>
                        </div>

                        <div class="grid grid-cols-5 items-center py-3">
                            <dt class="text-sm font-medium leading-6 text-gray-900 col-span-2">
                                Kruitgewicht (gr)
                            </dt>
                            <dd class="col-span-3">
                                <PPInput
                                    name="gunpowder_weight"
                                    type="number"
                                    step="0.1"
                                    v-model="productForm.gunpowderWeight"
                                    label=""
                                />
                            </dd>
                        </div>
                    </dl>
                </div>

                <hr class="my-5" />

                <h2 class="text-sm font-medium mb-3">Extra attributen toevoegen</h2>

                <template v-if="!attributes.length">
                    <p class="text-sm">
                        Er zijn nog geen
                        <a class="text-primary" :href="$router.resolve({ name: 'attributes' }).href"
                            >product attributen</a
                        >
                        aangemaakt.
                    </p>
                </template>

                <template v-else>
                    <div class="flex gap-3">
                        <PPButton
                            v-for="attribute in filteredAttributes"
                            :key="attribute.id"
                            @click="addAttribute(attribute)"
                        >
                            {{ attribute.name }} +
                        </PPButton>
                    </div>

                    <div class="border-y border-gray-100 mt-3" v-if="productForm.attributes.length">
                        <dl class="divide-y divide-gray-100">
                            <div
                                class="grid grid-cols-5 items-center py-3"
                                v-for="attribute in productForm.attributes"
                                :key="attribute.id"
                            >
                                <dt class="text-sm font-medium leading-6 text-gray-900 col-span-2">
                                    {{ attribute.name }}
                                </dt>
                                <dd class="col-span-2">
                                    <template
                                        v-if="
                                            attribute.type === 'string' || attribute.type === 'int'
                                        "
                                    >
                                        <PPInput
                                            :type="attribute.type === 'int' ? 'number' : 'text'"
                                            :step="attribute.name === 'Kruit per stuk' ? '0.1' : 'any'"
                                            name=""
                                            v-model="attribute.value"
                                            :placeholder="attribute.name"
                                        />
                                    </template>
                                    <template v-if="attribute.type === 'bool'">
                                        <PPToggle name="" v-model="attribute.value" />
                                    </template>
                                    <template v-if="attribute.type === 'textarea'">
                                        <PPTextArea label="" name="" v-model="attribute.value" />
                                    </template>
                                </dd>
                                <dd class="w-fit justify-end ml-auto">
                                    <PPButton
                                        color="danger"
                                        size="block"
                                        @click="removeAttribute(attribute)"
                                        >x
                                    </PPButton>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </template>
            </template>

            <template v-if="currentTab === 'Afbeeldingen'">
                <div v-if="filesToUpload.length">
                    <span class="font-medium">Klaar om te uploaden:</span>
                    <div class="inline-block ml-2" v-for="file in filesToUpload" :key="file.name">
                        {{ file.name }}
                    </div>
                </div>

                <div class="grid grid-cols-3 gap-5">
                    <div
                        class="flex flex-col flex-1 border p-2 justify-between gap-y-3"
                        v-for="image in gallery"
                        :key="image.id"
                    >
                        <img class="flex-1 object-contain" :src="image.sizes.large" alt="" />
                        <PPInput
                            name="title"
                            @change="updateImageAttribute(image.id, 'title', $event.target.value)"
                            v-model="image.title"
                            label="Titel"
                        />
                        <PPInput
                            name="description"
                            @change="
                                updateImageAttribute(image.id, 'description', $event.target.value)
                            "
                            v-model="image.description"
                            label="Beschrijving"
                        />
                        <PPInput
                            name="alt"
                            @change="updateImageAttribute(image.id, 'alt', $event.target.value)"
                            v-model="image.alt"
                            label="Alt"
                        />

                        <PPButton size="block" color="danger" @click="removeImage(image.id)">
                            Verwijder
                        </PPButton>
                    </div>
                </div>

                <PPFileInput
                    label="Toevoegen"
                    class="mt-5"
                    v-model="imageForm.file"
                    @change="onFileUpload"
                    name="file"
                    :disabled="isUploading"
                />
            </template>

            <template v-if="currentTab === 'Locaties'">
                <PPTable :headers="locationHeaders" :data="locations">
                    <template #cell(quantity)="{ row }">
                        <PPInput
                            @change="updateStock(row)"
                            :name="row['name']"
                            v-model="row['quantity']"
                        />
                    </template>
                    <template #cell(quantityPhysical)="{ row }">
                        <PPInput
                            @change="updateStock(row)"
                            :name="row['name']"
                            v-model="row['quantityPhysical']"
                        />
                    </template>
                </PPTable>
            </template>

            <div class="sticky-buttons">
                <hr class="my-5" />

                <PPButton @click="submit" type="button">Opslaan</PPButton>
                <PPButton class="ml-5" v-if="editMode" color="danger" @click="deleteVariant">
                    Verwijder
                </PPButton>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import PPInput from '@/components/PPInput.vue';
    import PPTextArea from '@/components/PPTextArea.vue';
    import PPButton from '@/components/PPButton.vue';
    import PPAlert from '@/components/PPAlert.vue';
    import PPFileInput from '@/components/PPFileInput.vue';
    import PPCategoryTree from '@/components/PPCategoryTree.vue';
    import PPTable from '@/components/PPTable.vue';
    import PPToggle from '@/components/PPToggle.vue';
    import PPSelect from '@/components/PPSelect.vue';
    import PPMultiSelect from '@/components/PPMultiSelect.vue';

    import { computed, inject, onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useFlashMessage } from '@/stores/flash-message';

    import { createSlugFromName } from '@/utils/utils';

    // Form and validation
    import { useVuelidate } from '@vuelidate/core';
    import { minValue, required } from '@vuelidate/validators';

    // Types
    import type { AxiosResponse } from 'axios';
    import type { Product } from '@/types/api/response/product';
    import type { Attribute } from '@/types/api/response/attribute';
    import type { DataTableHeader } from '@/types/datatable-header';
    import type { Option } from '@/components/PPSelect.vue';

    import { FlashMessageType } from '@/types/flash-message';

    type ImageForm = {
        file: any;
    };

    type ProductForm = {
        id?: string;
        published: boolean;
        code: string;
        name: string;
        slug: string;
        description: string;
        descriptionShort: string;
        metaKeywords: string;
        metaDescription: string;
        taxCategory: string;
        price: number;
        priceOriginal: number;
        category: string | null;
        weight: number;
        gunpowderWeight: number;
        attributes: Attribute[];
        taxonomies: [];
        mainTaxonomy?: string;
    };

    const axios = inject('axios');

    const route = useRoute();
    const router = useRouter();
    const flashMessage = useFlashMessage();
    const editMode = ref(false);
    const product = ref({});
    const gallery = ref([]);
    const attributes = ref([]);
    const channels = ref([]);
    const taxonomies = ref([]);
    const locations = ref([]);
    const taxCategories = ref([]);
    const categoryOptions: Option[] = [
        { name: 'Cat 1', value: 'cat1' },
        { name: 'Cat 2', value: 'cat2' }
    ];

    // Vertical navigation items
    const tabs = [
        { name: 'Details' },
        { name: 'Taxonomie' },
        { name: 'Attributen' },
        { name: 'Afbeeldingen' },
        { name: 'Locaties' }
    ];

    const showTabItem = (item) => {
        return true;
    };

    const currentTab = ref('Details');

    // Tree view data
    const treeview = ref(null);
    const treeDefaults = ref({
        expandable: false,
        selectable: false,
        focusable: false,
        input: {
            type: 'checkbox',
            value: 'id'
        },
        labelProperty: 'name'
    });

    // Location
    const locationHeaders: DataTableHeader[] = [
        {
            title: 'Locatie',
            key: 'name'
        },
        {
            title: 'Voorraad',
            key: 'quantity'
        },
        {
            title: 'Fysieke voorraad',
            key: 'quantityPhysical'
        }
    ];

    onMounted(async () => {
        await getLocations();
        await getChannels();
        await getTaxonomies();

        if (route.params.product && route.params.variant) {
            await getProduct();
            editMode.value = true;
        }

        await getTaxCategories();
        await getAttributes();
    });

    // Product
    const getProduct = async () => {
        try {
            const response: AxiosResponse<Product> = await axios.get(
                `/admin/product/${route.params.product}/variant/${route.params.variant}`
            );
            product.value = response.data;
            gallery.value = response.data.images ?? [];
            productForm.value = Object.assign({}, productForm.value, product.value);

            // Set the (gunpowder) weight in grams
            productForm.value.gunpowderWeight = product.value.gunpowderWeight / 10;
            productForm.value.weight = product.value.weight / 10;

            const kruitPerStuk = productForm.value.attributes.find(
                (a) => a.name === 'Kruit per stuk'
            );
            if (kruitPerStuk) {
                kruitPerStuk.value = kruitPerStuk.value / 10;
            }

            mergeStockLocations();
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const deleteVariant = async () => {
        try {
            const response = await axios.delete(
                `/admin/product/${route.params.product}/variant/${route.params.variant}`
            );

            if (response.status === 204) {
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: `Successfully deleted product variant!`
                });

                router.push({ name: 'products' });
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const onNameInput = (slug: string) => {
        productForm.value.slug = createSlugFromName(slug);
    };

    // Taxonomies
    const getTaxonomies = async () => {
        try {
            const response = await axios.get('/admin/taxonomies');

            if (response.status === 200) {
                taxonomies.value = response.data.map((t) => ({
                    name: t.name,
                    value: t.id,
                    ...t
                }));
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const onCheckboxChange = (taxonomy, event) => {
        const taxonomies = productForm.value.taxonomies;

        if (event.target.checked && !taxonomies.includes(taxonomy.id)) {
            taxonomies.push(taxonomy.id);
        } else if (!event.target.checked) {
            const index = taxonomies.findIndex((c) => c === taxonomy.id);
            taxonomies.splice(index, 1);
        }
    };

    // Channels
    const getChannels = async () => {
        try {
            const response = await axios.get('/admin/channels');

            if (response.status === 200) {
                channels.value = response.data.data.map((c) => ({
                    name: c.name,
                    value: c.id,
                    ...c
                }));
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    // Tax categories
    const getTaxCategories = async () => {
        try {
            const response = await axios.get('/admin/tax/categories');
            taxCategories.value = response.data.data.map((c) => ({ name: c.name, value: c.id }));
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    // Attributes
    const getAttributes = async () => {
        try {
            const response = await axios.get('/admin/product/attributes');

            if (response.status === 200) {
                attributes.value = response.data.data;
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const addAttribute = (attribute: Attribute) => {
        const value = attribute.type === 'bool' ? false : '';
        productForm.value.attributes.push({ attribute: attribute.id, value, ...attribute });
    };

    const removeAttribute = (attribute: Attribute) => {
        const index = productForm.value.attributes.findIndex(
            (a) => a.attribute === attribute.attribute
        );
        productForm.value.attributes.splice(index, 1);
    };

    const filteredAttributes = computed(() => {
        const allAttributes = attributes.value;
        const productAttributes = productForm.value.attributes;

        // Only display attributes that aren't already in the product attributes array
        return allAttributes.filter((a) => !productAttributes.find((p) => p.attribute === a.id));
    });

    // Images
    const imageForm = ref<ImageForm>({
        file: undefined
    });
    const isUploading = ref(false);

    const filesToUpload = ref([]);

    const onFileUpload = () => {
        const file = imageForm.value.file;

        if (!editMode.value) {
            filesToUpload.value.push(file);
        } else {
            uploadImage(file);
        }

        imageForm.value = { file: undefined };
    };

    const uploadImage = async (file: File, productId?: string, variantId?: string) => {
        const formData = new FormData();
        formData.set('image', file);

        if (!productId) {
            productId = route.params.product;
        }

        if (!variantId) {
            variantId = route.params.variant;
        }

        const url = `/admin/product/${productId}/variant/${variantId}/image`;

        try {
            const response = await axios.post(url, formData);

            if (response.status === 201) {
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: 'Successfully added image!'
                });

                gallery.value.push(response.data);
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const updateImageAttribute = async (id, key, value) => {
        const url = `/admin/product/${route.params.product}/variant/${route.params.variant}/image/${id}`;
        const data = {};
        data[key] = value;

        try {
            const response = await axios.patch(url, data);

            if (response.status === 200) {
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: 'Successfully updated image text!'
                });
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const removeImage = async (id) => {
        const url = `/admin/product/${route.params.product}/variant/${route.params.variant}/image/${id}`;

        try {
            const response = await axios.delete(url);

            if (response.status === 204) {
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: 'Successfully removed image!'
                });

                gallery.value = gallery.value.filter((image) => image.id !== id);
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    // Stock locations
    const getLocations = async () => {
        try {
            const response = await axios.get('/admin/stock/locations');

            if (response.status === 200) {
                locations.value = response.data.data.map((l) => ({ ...l, quantity: 0, quantityPhysical: 0 }));
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const mergeStockLocations = () => {
        // Loop over the stock on the product
        for (const stock of productForm.value.stock) {
            // Determine if the location exists on the stock
            const index = locations.value.findIndex((l) => l.id === stock.location.id);

            // If the location exists on the stock, set the correct quantity
            if (index >= 0) {
                locations.value[index].quantity = stock.quantity;
                locations.value[index].quantityPhysical = stock.quantityPhysical;
            }
        }

        // // After we're done merging, set the productForm stock to the locations,
        // // so we can correctly send it to the backend
        productForm.value.stock = locations.value.map((l) => ({
            location: l.id,
            quantity: l.quantity,
            quantityPhysical: l.quantityPhysical
        }));
    };

    const updateStock = (location) => {
        const locations = productForm.value.stock;
        // Check if the location already exists
        const index = locations.findIndex((l) => l.location === location.id);

        // Make up the data for the backend
        const stock = {
            location: location.id,
            quantity: parseInt(location.quantity),
            quantityPhysical: parseInt(location.quantityPhysical)
        };

        // If the location exists on the stock, edit the quantity
        // Otherwise push it to the array as a new location
        if (index >= 0) {
            locations[index] = stock;
        } else {
            locations.push(stock);
        }
    };

    const productForm = ref<ProductForm>({
        published: false,
        code: '',
        pickCode: null,
        name: '',
        slug: '',
        channels: [],
        price: 0,
        priceOriginal: 0,
        description: '',
        descriptionShort: '',
        metaKeywords: '',
        metaDescription: '',
        category: null,
        weight: 0,
        gunpowderWeight: 0,
        attributes: [],
        taxonomies: [],
        stock: [],
        taxCategory: null,
        mainTaxonomy: null
    });

    const rules = {
        published: {},
        code: { required },
        pickCode: {},
        name: { required },
        slug: { required },
        channels: {},
        taxCategory: {},
        price: { minValue: minValue(0) },
        priceOriginal: { minValue: minValue(0) },
        weight: { minValue: minValue(0) },
        gunpowderWeight: { minValue: minValue(0) },
        description: {},
        descriptionShort: {},
        metaKeywords: {},
        metaDescription: {},
        mainTaxonomy: {}
    };

    const v$ = useVuelidate(rules, productForm);
    const error = ref('');

    // Form submission
    const submit = async () => {
        error.value = '';

        const result = await v$.value.$validate();

        if (!result) {
            error.value = 'Please check fields!';
            return;
        }

        let url = '/admin/product';

        if (route.params.product) {
            url += `/${route.params.product}/variant`;

            if (route.params.variant) {
                url += `/${route.params.variant}`;
            }
        }

        const method = editMode.value ? 'PATCH' : 'POST';
        const data: any = Object.assign({}, productForm.value);

        if (data.pickCode === '') {
            data.pickCode = null;
        }

        // Set the gunpowderWeight and weight back to integers
        data.gunpowderWeight = data.gunpowderWeight * 10;
        data.weight = data.weight * 10;

        const kruitPerStuk = data.attributes.find((a) => a.name === 'Kruit per stuk');
        if (kruitPerStuk) {
            kruitPerStuk.value = kruitPerStuk.value * 10;
        }

        // Make a new array from the channels with only IDs
        data.channels = Array.from(data.channels, (channel) => channel.id);

        try {
            const response = await axios({ method, url, data });

            if (response.status === 201) {
                const action = editMode.value ? 'updated' : 'created';

                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: `Successfully ${action} product!`
                });

                if (filesToUpload.value.length) {
                    const variantId = response.data.id;
                    const productId = response.data.product;

                    for (const file: File of filesToUpload.value) {
                        await uploadImage(file, productId, variantId);
                    }
                }
            }

            await router.push({ name: 'products' });
        } catch (e) {
            error.value = 'Something went wrong while updating or creating product!';

            if (e.data.detail) {
                error.value = e.data.detail;
            }

            console.error(error.value, e);
        }
    };
</script>
