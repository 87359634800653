<template>
    <div>
        <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
        <div class="mt-1 flex flex-col gap-y-2">
            <div v-for="option in options" :key="option.name" class="relative flex items-start">
                <div class="flex h-6 items-center">
                    <input
                        :id="option.name"
                        :name="option.name"
                        type="radio"
                        :checked="option.name === modelValue.value"
                        :value="option.name"
                        @change="$emit('update:modelValue', picked)"
                        v-model="picked"
                        class="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                    />
                </div>
                <div class="ml-3 text-sm leading-6">
                    <label :for="option.name" class="font-medium text-gray-700">{{
                        option.name
                    }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    export interface Option {
        name: string;
    }

    const props = defineProps<{
        label: string;
        name: string;
        modelValue: any;
        allowNone?: boolean;
        options: Option[];
        errors?: number;
    }>();

    const picked = ref(null);

    const emits = defineEmits(['update:modelValue']);
</script>
