<template>
    <div>
        <Combobox as="div" v-model="selected">
            <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">
                {{ label }}
            </ComboboxLabel>
            <div class="relative mt-1 flex flex-col gap-y-2">
                <ComboboxInput
                    class="w-full border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    @submit.prevent
                    @keyup.enter="addTag(selected)"
                    @change="query = $event.target.value"
                    :display-value="(item) => item?.name"
                />
                <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none"
                >
                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions
                    v-if="filteredData.length > 0"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ComboboxOption
                        v-for="item in filteredData"
                        :key="item.id"
                        :value="item"
                        as="template"
                        v-slot="{ active, selected }"
                        @click="addTag(selected)"
                    >
                        <li
                            :class="[
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-primary text-white' : 'text-gray-900'
                            ]"
                        >
                            <span :class="['block truncate', selected && 'font-semibold']">
                                {{ item.name }}
                            </span>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>
        <div class="mt-2 text-sm text-gray-500 -mb-2">
            <span
                class="inline-flex items-center py-0.5 pl-2.5 pr-1 mr-2 mb-2 text-sm bg-gray-100 text-black"
                v-for="(tag, index) in modelValue"
            >
                {{ tag?.name }}
                <button
                    type="button"
                    @click="removeTag(index)"
                    class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center"
                >
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions
    } from '@headlessui/vue';

    import { ChevronDownIcon } from '@heroicons/vue/20/solid';

    import { ref, computed } from 'vue';

    const props = defineProps(['options', 'name', 'label', 'modelValue', 'errors']);
    const selected = ref(null);
    const query = ref('');

    const filteredData = computed(() => {
        return query.value === ''
            ? props.options
            : props.options.filter((item) => {
                  return item.name.toLowerCase().includes(query.value.toLowerCase());
              });
    });

    const addTag = (item) => {
        if (item && !hasTag(item)) {
            props.modelValue.push(selected.value);
        }

        selected.value = null;
        query.value = '';
    };
    const removeTag = (index) => {
        props.modelValue.splice(index, 1);
    };
    const hasTag = (item) => {
        return props.modelValue.find((e) => e?.id === item?.id) !== undefined;
    };
</script>
