<template>
    <div>
        <label for="email" class="block text-sm font-medium text-gray-700">{{ label }}</label>
        <div class="mt-1">
            <textarea
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                rows="4"
                :placeholder="placeholder ?? label"
                :name="name"
                :readonly="readonly"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :class="[inputClass, errors ? 'border-danger' : 'border-gray-300']"
                class="focus:ring-primary focus:border-primary block w-full text-sm rounded-sm transition duration-300"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    const props = defineProps<{
        disabled?: boolean;
        errors?: number;
        label: string;
        modelValue: null;
        name: string;
        placeholder?: string;
        readonly?: boolean;
        autocomplete?: string;
    }>();

    const inputClass = computed(() => {
        if (props.disabled) {
            return 'bg-gray-200';
        }

        return '';
    });

    const emits = defineEmits<{
        (e: 'update:modelValue', value: string | number): void;
    }>();
</script>
