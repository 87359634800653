<template>
    <PPHeader title="Producten" href="new-product" />

    <div class="mt-3">
        <PPButton @click="showFilters = true">Filters</PPButton>
    </div>

    <div class="grid grid-cols-4 space-x-4">
<!--        <div class="col-span-1">-->
<!--            <PPCategoryTree :onNodeClicked="target => onTreeClicked(target)" />-->
<!--        </div>-->

        <div class="mt-3 col-span-4">
            <PPDataTable
                :key="dataTableKey"
                :headers="headers"
                :resourceUrl="resourceUrl"
                :filter="true"
                :rowClicked="row => onRowClicked(row)"
            >
                <template #cell(action)="{row}">
                    <div class="flex gap-x-3">
                        <PPButton @click="$router.push({ name: 'product-details', params: { product: row.product }})">Alle varianten</PPButton>
                        <PPButton @click="$router.push({ name: 'new-variant', params: { product: row.product }})">Nieuwe variant</PPButton>
                        <PPButton @click="$router.push({ name: 'edit-variant', params: { product: row.product, variant: row.id }})">Bewerk variant</PPButton>
                    </div>
                </template>

                <template #cell(images)="{value}">
                    <img class="w-12 h-12 object-contain" :src="value[0]?.sizes.small ?? '/src/assets/green_logo.svg'" alt="">
                </template>
            </PPDataTable>
        </div>
    </div>

    <TransitionRoot as="template" :show="showFilters">
        <Dialog as="div" class="relative z-50" @close="showFilters = false">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-300"
                            enter-from="-translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-300"
                            leave-from="translate-x-0"
                            leave-to="-translate-x-full"
                        >
                            <DialogPanel class="pointer-events-auto w-72">
                                <div
                                    class="flex h-full flex-col overflow-y-auto bg-white py-4 shadow-xl"
                                >
                                    <div class="px-4 sm:px-6 pb-4 border-b">
                                        <div class="flex items-center justify-between">
                                            <DialogTitle
                                                class="text-base font-semibold leading-6 text-gray-900"
                                                >Filters
                                            </DialogTitle>
                                            <div class="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                                    @click="showFilters = false"
                                                >
                                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="relative mt-6 flex-1 px-4 sm:px-6 gap-y-8 flex flex-col"
                                    >
                                        <!-- Filters -->
                                        <PPMultiCheckbox
                                            :options="[
                                                { name: 'Kindervuurwerk' },
                                                { name: 'Siervuurwerk' }
                                            ]"
                                            name="filters"
                                            v-model="categoryFilters"
                                            label="Categories"
                                        />

                                        {{ categoryFilters }}

                                        <PPRadio
                                            name="product_type"
                                            :options="[
                                                { name: 'Vuurpijlen' },
                                                { name: 'Fontijnen' }
                                            ]"
                                            v-model="productFilters"
                                            label="Product type"
                                        />

                                        {{ productFilters }}

                                        <PPToggle
                                            name="in_stock"
                                            v-model="inStock"
                                            label="Alleen op voorraad"
                                        />

                                        {{ inStock }}
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
    import PPDataTable from '@/components/PPDataTable.vue';
    import PPHeader from '@/components/PPHeader.vue';
    import PPMultiCheckbox from '@/components/PPMultiCheckbox.vue';
    import PPRadio from '@/components/PPRadio.vue';
    import PPToggle from '@/components/PPToggle.vue';
    import PPButton from '@/components/PPButton.vue';
    import PPCategoryTree from '@/components/PPCategoryTree.vue';

    import {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot
    } from '@headlessui/vue';
    import { XMarkIcon } from '@heroicons/vue/24/outline';

    import { inject, onBeforeMount, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    // import Product from '@/types/product'

    const axios = inject('axios');
    const route = useRoute();
    const router = useRouter();

    const showFilters = ref(false);
    const productFilters = ref('');
    const categoryFilters = ref([]);
    const inStock = ref(false);

    // Datatable
    const resourceUrl = ref('/admin/product/variants');
    const dataTableKey = ref(0);

    // Make sure we get the products from the correct category
    onBeforeMount(() => {
        loadCategoryProducts();
    });

    watch(
        route,
        () => {
            loadCategoryProducts();
        },
        { deep: true }
    );

    const loadCategoryProducts = () => {
        // If we have a ID in the route params, load the category products
        if (route.params.id) {
            resourceUrl.value = `/products/category/${route.params.id}`;
            dataTableKey.value += 1;
        }
    };

    // Datatable headers
    const headers = [
        // {key: 'id', title: 'ID'},
        { key: 'images', title: 'Afbeelding' },
        { key: 'code', title: 'Code' },
        { key: 'slug', title: 'Slug' },
        { key: 'name', title: 'Naam' },
        { key: 'action', title: '' },
    ];

    const onRowClicked = (row) => {
        router.push({ name: 'edit-variant', params: { product: row.product, variant: row.id } })
    }

    // Treeview
    const onTreeClicked = (target) => {
        router.push({ name: 'product-category', params: { id: target.id } });
    };
</script>
