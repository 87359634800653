<template>
    <div>
        <label for="location" class="block text-sm font-medium text-gray-700">{{ label }}</label>
        <select
            :value="modelValue"
            @input="updateValue"
            :id="name"
            :name="name"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-sm focus:outline-none focus:ring-primary focus:border-primary rounded-sm"
            :class="[errors ? 'border-danger' : 'border-gray-300']"
            :disabled="disabled"
        >
            <option value="" disabled>Kies een optie</option>
            <option value="" v-if="allowNone">Geen</option>
            <option v-for="option in options" :value="option.value">{{ option.name }}</option>
        </select>
    </div>
</template>

<script setup lang="ts">
    export interface Option {
        name: string;
        value: string | number;
    }

    const props = defineProps<{
        label: string;
        name: string;
        modelValue: any;
        allowNone?: boolean;
        options: Option[];
        errors?: number;
        disabled?: boolean;
    }>();

    const emits = defineEmits(['update:modelValue']);

    const updateValue = (e) => {
        let value = e.target.value;

        if (value === '' || value === undefined) {
            value = null;
        }

        emits('update:modelValue', value);
    };
</script>
