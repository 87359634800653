<template>
    <PPHeader title="Product variant(en)" />

    <div class="grid grid-cols-4 space-x-4">

        <div class="mt-3 col-span-4">
            <PPDataTable
                :key="dataTableKey"
                :headers="headers"
                :resourceUrl="resourceUrl"
                :filter="true"
                :rowClicked="row => onRowClicked(row)"
            >
                <template #cell(action)="{row}">
                    <span class="text-primary">Bewerken</span>
                </template>

                <template #cell(images)="{value}">
                    <img class="w-24 h-24 object-contain" :src="value[0]?.sizes.small ?? '/src/assets/green_logo.svg'" alt="">
                </template>
            </PPDataTable>
        </div>
    </div>
</template>

<script setup lang="ts">
import PPDataTable from '@/components/PPDataTable.vue';
import PPHeader from '@/components/PPHeader.vue';

import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// import Product from '@/types/product'

const route = useRoute();
const router = useRouter();

// Datatable
const resourceUrl = ref(`/admin/product/${route.params.product}/variants`);
const dataTableKey = ref(0);

// Datatable headers
const headers = [
    // {key: 'id', title: 'ID'},
    { key: 'images', title: 'Afbeelding' },
    { key: 'code', title: 'Code' },
    { key: 'slug', title: 'Slug' },
    { key: 'name', title: 'Naam' },
    { key: 'action', title: '' },
];

const onRowClicked = (row) => {
    router.push({ name: 'edit-variant', params: { product: row.product, variant: row.id } })
}
</script>
